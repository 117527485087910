import React, { useContext } from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import VisibilitySensor from "react-visibility-sensor"
import { Link } from "gatsby"
import { ActiveScrollContext } from "../hooks/ActiveScrollContext"
import { Avatar } from "../templates/profile"

const BodyContent = styled("div")`
  h4 {
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: "montserrat";
  }

  .team-description {
    margin-bottom: 3rem;

    p {
      font-size: 1rem !important;
    }
  }

  .anchor {
    display: block;
    position: relative;
    top: -180px;
    visibility: hidden;
  }
`

const ProfileContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Profile = styled("div")`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  flex: 0 0 48%;
  padding-right: 1rem;

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }

  .profile-link {
    color: black;
    text-decoration: none;
    flex: 1;
    display: flex;
    align-items: flex-start;
  }

  .avatar-container {
    flex: 0 0 80px;
    height: 80px;
    position: relative;
  }

  .avatar-circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: solid 3px #0099cb;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
    opacity: 0;
  }

  .avatar {
    width: 80px;
    height: auto;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.other-profile {
    flex: 0 0 31%;

    .avatar-container {
      flex: 0 0 60px;
      height: 60px;
    }

    .avatar {
      width: 60px;
    }
  }

  &:hover {
    .avatar-circle {
      opacity: 1;
    }
  }

  .info {
    flex: 1;
    margin-left: 1rem;

    h5,
    p {
      margin: 0;
      margin-bottom: 0.25rem;
    }

    .name {
      font-size: 1rem;

      a {
        color: black;
      }
    }

    .title {
      font-weight: 400;
    }

    .title,
    .description {
      font-size: 0.9rem;
    }
  }
`

const TeamContainer = ({ doc }) => {
  const [, setActiveItems] = useContext(ActiveScrollContext)
  function onChange(isVisible, id) {
    setActiveItems({ id, isVisible })
  }
  return (
    <BodyContent>
      {doc.node.body &&
        doc.node.body.map(b =>
          b.type === "management_team" ? (
            <VisibilitySensor
              key={b.type}
              onChange={isVisible =>
                onChange(
                  isVisible,
                  b.primary.team_name &&
                    RichText.asText(b.primary.team_name) &&
                    RichText.asText(b.primary.team_name).replace(" ", "_")
                )
              }
            >
              <div>
                <div
                  className="anchor"
                  id={
                    b.primary.team_name &&
                    RichText.asText(b.primary.team_name) &&
                    RichText.asText(b.primary.team_name).replace(" ", "_")
                  }
                />
                <h4>{RichText.asText(b.primary.team_name)}</h4>
                <div className="team-description">
                  {b.primary.team_description &&
                    RichText.render(b.primary.team_description)}
                </div>
                <ProfileContainer>
                  {b.fields &&
                    b.fields.map((f, i) => (
                      <Profile key={i}>
                        <Link
                          className="profile-link"
                          to={`/team/${f.full_profile._meta &&
                            f.full_profile._meta.uid}`}
                        >
                          <div className="avatar-container">
                            <span className="avatar-circle" />
                            <Avatar
                              imageUrl={
                                f.full_profile.avatar &&
                                f.full_profile.avatar.url
                              }
                              style={{ width: 80, height: 80 }}
                            />
                          </div>
                          <div className="info">
                            <h5 className="name">
                              {f.full_profile.name &&
                                RichText.asText(f.full_profile.name)}
                            </h5>
                            <p className="title">
                              {f.full_profile.position &&
                                RichText.asText(f.full_profile.position)}
                            </p>
                            <p className="description">
                              {f.full_profile.short_description &&
                                RichText.asText(
                                  f.full_profile.short_description
                                )}
                            </p>
                          </div>
                        </Link>
                      </Profile>
                    ))}
                </ProfileContainer>
              </div>
            </VisibilitySensor>
          ) : b.type === "other_team" ? (
            <VisibilitySensor
              key={b.type}
              onChange={isVisible =>
                onChange(
                  isVisible,
                  b.primary.team_name &&
                    RichText.asText(b.primary.team_name) &&
                    RichText.asText(b.primary.team_name).replace(" ", "_")
                )
              }
            >
              <div>
                <div
                  className="anchor"
                  id={
                    b.primary.team_name &&
                    RichText.asText(b.primary.team_name) &&
                    RichText.asText(b.primary.team_name).replace(" ", "_")
                  }
                />
                <h4>
                  {b.primary.team_name && RichText.asText(b.primary.team_name)}
                </h4>
                <div className="team-description">
                  {b.primary.team_description &&
                    RichText.render(b.primary.team_description)}
                </div>
                <ProfileContainer>
                  {b.fields &&
                    b.fields.map((f, i) => (
                      <Profile key={i} className="other-profile">
                        <div className="avatar-container">
                          <img
                            className="avatar"
                            src={f.avatar && f.avatar.url}
                            alt={f.avatar && f.avatar.alt}
                          />
                        </div>
                        <div className="info">
                          <h5 className="name">
                            {f.name && RichText.asText(f.name)}
                          </h5>
                          <p className="title">
                            {f.position && RichText.asText(f.position)}
                          </p>
                        </div>
                      </Profile>
                    ))}
                </ProfileContainer>
              </div>
            </VisibilitySensor>
          ) : null
        )}
    </BodyContent>
  )
}

export default TeamContainer
