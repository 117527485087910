import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { ContentSection } from "../components/ContentSection"
import SideNav from "../components/SideNav"
import TwoColContainer from "../components/TwoColContainer"
import TopSubNav from "../components/TopSubNav"
import ActionButtonLink from "../components/ActionButtonLink"
import CallToAction from "../components/CallToAction"
import TeamContainer from "../components/TeamContainer"
import { formatRegularLinks } from "../hooks/useFormattedRegularLinks"
import { RichText } from "prismic-reactjs"

export const query = graphql`
  {
    prismic {
      allTeams {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            page_title
            page_intro
            body {
              ... on PRISMIC_TeamBodyManagement_team {
                type
                label
                fields {
                  full_profile {
                    ... on PRISMIC_Profile {
                      avatar
                      name
                      _meta {
                        uid
                        type
                      }
                      full_bio
                      position
                      short_description
                    }
                  }
                }
                primary {
                  team_name
                  team_description
                }
              }
              ... on PRISMIC_TeamBodyOther_team {
                type
                label
                fields {
                  avatar
                  name
                  position
                }
                primary {
                  team_name
                  team_description
                }
              }
            }
            top_sub_nav {
              nav_item
            }
          }
        }
      }
    }
  }
`

const links = [
  {
    key: "about",
    stub: "/about",
    displayName: "About",
  },
  {
    key: "team",
    stub: "/team",
    displayName: "Team",
  },
  {
    key: "capabilities",
    stub: "/capabilities",
    displayName: "Capabilities",
  },
  {
    key: "culture",
    stub: "/culture",
    displayName: "Culture",
  },
  {
    key: "careers",
    stub: "/careers",
    displayName: "Careers",
  },
]

const renderPage = data => {
  const doc = data.prismic.allTeams.edges.slice(0, 1).pop()
  if (!doc) return null

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  const topScrollLinks = doc.node.body
    .filter(
      b =>
        !!(
          b.primary.team_name &&
          b.primary.team_name[0] &&
          b.primary.team_name[0].text
        )
    )
    .map(b => ({
      id: b.primary.team_name[0].text.replace(" ", "_"),
      label: b.primary.team_name[0].text,
      isVisible: false,
    }))

  const formattedRegularLinks = formatRegularLinks(doc.node.top_sub_nav)

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={doc.node.page_title && RichText.asText(doc.node.page_title)}
        topScrollLinks={topScrollLinks}
        regularLinks={formattedRegularLinks}
      />
      <ContentSection>
        <div className="inner-container">
          <TwoColContainer>
            <div className="left-col">
              <SideNav links={links} />
            </div>
            <div className="right-col">
              {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
              <p class="intro-text">
                {doc.node.page_intro && RichText.asText(doc.node.page_intro)}
              </p>
              <TeamContainer doc={doc} />
            </div>
          </TwoColContainer>
        </div>
      </ContentSection>
      <CallToAction>
        <div className="inner-container">
          <div className="message">
            <p>Interested in joining our team?</p>
          </div>
          <div className="action-button">
            <ActionButtonLink to="/careers">Join Us</ActionButtonLink>
          </div>
        </div>
      </CallToAction>
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderPage, query)} />
    </>
  )
}
